import { billingDaysChoices, getInvalidBillingDays } from "@smartrr/shared/utils/dateUtils";
import { useEffect, useMemo, useState } from "react";
import { SetupStoreAccess } from "../../../AdminSetupRoute/libs/store";

export const useDisabledDatesByBillingDays = (day: Date, omitDates?: Date[] | undefined) => {
  const dayVals = useMemo(() => billingDaysChoices.map(billingDay => billingDay.value), []);
  const setup = SetupStoreAccess.useSetup();
  const isLoading = SetupStoreAccess.useLoading();
  const [selectedBillingDays, setSelectedBillingDays] = useState<string[]>(setup?.billingDays ?? dayVals);

  useEffect(() => {
    if (!isLoading && setup?.billingDays) {
      setSelectedBillingDays(setup.billingDays);
    }
  }, [isLoading, setup?.billingDays]);

  return useMemo(() => getInvalidBillingDays(selectedBillingDays, day, omitDates), [selectedBillingDays, day]);
};
